<template>
    <div class="homeBg">
        <div class="topBox">
            <div class="topLeft">
                <div class="titleBox">
                    <div>
                        <img src="../../assets/siteImg/title.png"/>
                        {{ museumName }}
                    </div>
                    <el-tree-select 
                    @change="buildChange"
                    v-model="build" 
                    node-key="envId" 
                    :data="builds" 
                    check-strictly 
                    :props="defaultProps"/>
                </div>
                <div class="MainbotBox">
                    <mapMode ref="mapMode" @mapEvent="mapEvent"/>
                </div>
            </div>
            <div class="topRight">
                <div class="titleBox">
                    <div>
                        <img src="../../assets/siteImg/title.png"/>
                        文物列表
                    </div>
                    <div></div>
                </div>
                <div class="MainbotBox">
                    <div class="topStatu">
                        <div class="itemBox"><span style="color: #00B219;">■</span>{{ status[0] }}</div>
                        <div class="itemBox"><span style="color: #FFD800;">■</span>{{ status[1] }}</div>
                        <div class="itemBox"><span style="color: #FF7A19;">■</span>{{ status[2] }}</div>
                        <div class="itemBox"><span style="color: #FF2828;">■</span>{{ status[3] }}</div>
                    </div>
                    <el-table :data="tableData" style="width: 100%">
                        <el-table-column prop="culturalName" label="文物名称" />
                        <el-table-column prop="texture" label="材质" />
                        <el-table-column prop="locationName" label="位置" />
                        <el-table-column label="等级">
                            <template #default="scope">
                                <span style="color: #00B219;" v-if="scope.row.sensorState==0">优</span>
                                <span style="color: #FFD800;" v-if="scope.row.sensorState==1">良</span>
                                <span style="color: #FF7A19;" v-if="scope.row.sensorState==2">中</span>
                                <span style="color: #FF2828;" v-if="scope.row.sensorState==3">差</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-button type="primary" plain size="small" @click="lookMsg(scope.row)">详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination 
                    v-model:current-page="query.page"
                    :page-size="query.size"
                    @current-change="pageChange"
                    layout="prev, pager, next" 
                    :total="total" />
                </div>
            </div>
        </div>
        <div class="botBox">
            <div class="titleBox">
                <div>
                    <img src="../../assets/siteImg/title.png"/>
                    监测指标状况
                </div>
                <div></div>
            </div>
            <div class="MainbotBox">
                <div class="operBar">
                    <el-radio-group v-model="typeSener" @change="changeRadio">
                        <el-radio-button label="02">温度</el-radio-button>
                        <el-radio-button label="01">湿度</el-radio-button>
                        <el-radio-button label="04">光照</el-radio-button>
                        <el-radio-button label="05">紫外</el-radio-button>
                        <el-radio-button label="03">CO₂</el-radio-button>
                        <el-radio-button label="06">VOC</el-radio-button>
                    </el-radio-group>
                </div>
                <div class="lineChart">
                    <lineChart :series="lineSeries" v-if="lineSeries.length>0"/>
                </div>
            </div>
        </div>
        <relicMsg ref="relicMsg"/>
    </div>
</template>
<script>
import mapMode from '@/components/leaflet/index.vue'
import { site_envList, site_envMap, site_relics, site_countRelicNum, site_history } from '@/api/site-home.js'
import { siteOper_devList } from '@/api/site-operationPage.js'
import { mapGetters } from 'vuex'
import lineChart from './modules/lineChart.vue'
import relicMsg from './modules/relicMsg.vue'
import moment from 'moment'
export default{
    components:{
        mapMode,
        lineChart,
        relicMsg
    },
    computed:{
        ...mapGetters(['siteId'])
    },
    data(){
        return{
            museumName:'',
            build:null,
            builds:[],
            defaultProps:{
                children: 'children',
                label: 'envName',
            },
            tableData:[],
            query:{
                page:1,
                size:6,
            },
            total:0,
            lineSeries:[],
            typeSener:'01',

            floatType:{
                label:'温度',
                unit:'℃'
            },
            senerSAll:[
                {label:'湿度',id:'01',unit:'%RH'},
                {label:'温度',id:'02',unit:'℃'},
                {label:'二氧化碳',id:'03',unit:'ppm'},
                {label:'光照度',id:'04',unit:'lx'},
                {label:'紫外辐射强度',id:'05',unit:'μw/cm2'},
                {label:'有机挥发物总量VOC（异丁烯）',id:'06',unit:'ppm'},
                {label:'有机污染物',id:'07',unit:'mg/m²'},
                {label:'无机污染物',id:'08',unit:'mg/m²'},
                {label:'含硫污染物',id:'09',unit:'mg/m²'},
            ],
            status:{},
            deviceId:null,
        }
    },
    mounted(){
        this.getList()
    },
    methods:{
        moment,
        mapEvent(e){
           if(e.target.options.data){
                let data = e.target.options.data
                this.deviceId = data.deviceId
                this.getLine()
           }
        },
        getList(){
            let minQuery = {
                'siteId':this.siteId,
                'envId':this.siteId,
                'isSelf':true,
                'isTree':true,
            }
            site_envList(minQuery).then(res=>{
                this.builds = res.data[0].children
                this.museumName = res.data[0].envName
                if(this.$route.query.buildId){
                    this.buildChange(this.$route.query.buildId)
                }
                
            })
        },
        getMap(){
            let minQuery = {
                'siteId':this.siteId,
                'envid':this.build
            }
            site_envMap(minQuery).then(res=>{
                let data = res.data.envs
                let src = `${window.g.imgPath}${res.data.layoutUrl}`
                let line = []
                data.forEach(el => {
                    let obj = {
                        type:'polygon',
                        points:JSON.parse(el.latlngs),
                        name:el.envName,
                        envId:el.envId 
                    }
                    line.push(obj)
                });
                if(res.data.layoutUrl){
                    let devices = res.data.devices
                    if(devices.length>0){
                        siteOper_devList(minQuery).then(r=>{
                            let devTable = r.data.map(i=>{
                                let obj = i
                                let device = devices.find((val) => val.deviceId == i.deviceId)
                                if(device&&device.latlng){
                                    obj['latlng'] = device.latlng
                                }else{
                                    obj['latlng'] = null
                                }
                                return  obj
                            })
                            devTable.forEach(el => {
                                if(el.latlng){
                                    let obj = {
                                        type:'maker',
                                        points:el.latlng.split(','),
                                        name:el.deviceName,
                                        deviceId:el.deviceId,
                                        deviceType:el.deviceType,
                                        deviceStatus:el.deviceStatus,
                                        deviceParamsVal:el.deviceParamsVal
                                    }
                                    line.push(obj)
                                }
                            });
                            this.$refs.mapMode.init(src, line)
                        })
                    }else{
                        this.$refs.mapMode.init(src, line)
                    }                    
                }else{
                    src = require("../../assets/newSite/empty.png")
                    this.$refs.mapMode.init(src, line)
                }
            })
        },
        getLine(){
            let query = {
                deviceId:this.deviceId,
                siteId:this.siteId,
                startDateTime:moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
                endDateTime:moment().format('YYYY-MM-DD HH:mm:ss'),
                envirParamType:this.typeSener
            }
            this.floatType = this.senerSAll.find(i=>{
                return i.id == this.typeSener
            })
            site_history(query).then(r=>{
                let data = r.data
                this.lineSeries = [{
                    name: `${data.deviceName}-${this.floatType.label}(${this.floatType.unit})`,
                    type: 'line',
                    data:data.data.map(i=>{
                        return [i.collectTime, i.envirParamValue]
                    })
                }]
            })
        },
        buildChange(val){
            this.build = val
            this.getMap()
            this.getRelic(0)
            this.getCount()
            this.clearLine()
        },
        clearLine(){
            this.deviceId = null
            this.lineSeries = []
        },
        getCount(){
            let query = {
                envId:this.build,
                code:this.siteId
            }
            site_countRelicNum(query).then(r=>{
                this.status = r.data[0]
            })
        },
        getRelic(type){
            if(type==0){
                this.query.page = 1
            }
            let Query = {
                envId:this.build,
                page:this.query.page,
                size:this.query.size,
                code:this.siteId
            }
            site_relics(Query).then(r=>{
                this.tableData = r.data.data.map(i=>{
                    let obj = i
                    obj['relicdetail'] = JSON.parse(i.relicdetail)
                    return obj
                })
                this.total = r.data.pageInfo.total
            })
        },
        lookMsg(data){
            this.$refs.relicMsg.open(data)
        },
        pageChange(page){
            this.query.page = page
            this.getRelic(1)
        },
        changeRadio(val){
            this.typeSener = val
            if(this.deviceId){
                this.getLine()
            }
            
        },
        
        makeData(){
            let base = +new Date(2023, 11, 1);
            let oneDay = 24 * 3600 * 1000;
            let data = [[base, Math.random() * 300]];
            for (let i = 1; i < 20; i++) {
                let now = new Date((base += oneDay));
                data.push([+now, Math.round((Math.random() - 0.5) * 20 + data[i - 1][1])]);
            }
            return data
        }, 
    }
}
</script>
<style lang="scss" scoped>
.homeBg{
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: #ECF0F7;
    box-sizing: border-box;   
}
.topBox{
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .topLeft{
        width: calc(65% - 10px);
        height: 100%;
        background-color: white;
        border-radius: 10px;
        padding:0px 20px;
        box-sizing: border-box;
    }
    .topRight{
        width: 35%;
        height: 100%;
        background-color: white;
        border-radius: 10px;
        padding: 0px 20px;
        box-sizing: border-box;
        .topStatu{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .itemBox{
                margin-right: 20px;
            }
        }
    }
}
.botBox{
    width: 100%;
    height: 400px;
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
    padding: 0px 20px;
    box-sizing: border-box;
    .operBar{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .lineChart{
        width: 100%;
        height: calc(100% - 40px);
    }
}
.titleBox{
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0px 20px;
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
        margin-right: 4px;
        vertical-align: middle;
    }
}
.MainbotBox{
    width: 100%;
    height: calc(100% - 50px);
}
/deep/.el-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
</style>